import React from 'react';
import {Container, Navbar, Nav, Button} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

export default function MainPageNav() {
  return (
    <div>
      <Navbar bg="white" expand="lg" fixed="top">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand href="/">Adam Farver</Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav id="main-nav" className="ms-auto">
              {/* For use with blog. */}
              <Nav.Link active={false} href="/#about" className="nav-link">
                About
              </Nav.Link>
              <Nav.Link active={false} href="/#tools" className="nav-link">
                Tools
              </Nav.Link>
              <Nav.Link active={false} href="/#work" className="nav-link">
                Work
              </Nav.Link>

              {/* <LinkContainer className="nav-link" to="/blog">
                <Nav.Link>Blog</Nav.Link>
              </LinkContainer> */}

              <Button
                className="ms-2"
                variant="outline-primary"
                href="mailto:adamfarver@gmail.com?subject=I want to work with you!&body=Hey!"
                target="_blank"
              >
                Contact
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
