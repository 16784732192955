import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
export default function Footer() {
  return (
    <div>
      <section>
        <Container className="footer" id="footer">
          <Row className="my-5 ">
            <Col>
              <h6 className="text-center">Made with React and Sanity</h6>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
