import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import tracker from "../../assets/timetracker.png";
import guessNumber from "../../assets/guessnumber.png";
import dashboard from "../../assets/fantastictelegram.png";
import { LinkContainer } from "react-router-bootstrap";

export default function Workarea() {
  return (
    <div>
      <section>
        <Container name="work" className="work my-5" id="work">
          <Row>
            <Col>
              <h2>Some of my work</h2>
            </Col>
          </Row>
          <Row>
            <Col md="4" className="mb-3 d-flex align-items-stretch">
              <Card className="img-crop">
                <Card.Img variant="top" className="img-crop" src={tracker} />
                <Card.Body>
                  <Card.Title>Time Tracker</Card.Title>
                  <Card.Subtitle className="text-muted">
                    Full-Stack App
                  </Card.Subtitle>
                  <Card.Text>
                    Multi-user time tracking app with burn-down graphs.
                  </Card.Text>
                  <LinkContainer to="/timetracker">
                    <Button variant="primary">More Info</Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>

            <Col
              md="4"
              className="mb-3
            d-flex align-items-stretch
            "
            >
              <Card>
                <Card.Img variant="top" src={dashboard} />
                <Card.Body>
                  <Card.Title>Speed Test Dashboard</Card.Title>
                  <Card.Subtitle className="text-muted">
                    Full-Stack App
                  </Card.Subtitle>
                  <Card.Text>
                    Keep an eye on your network throughput with aggregated data.
                  </Card.Text>

                  <LinkContainer to="/dashboard">
                    <Button variant="primary">More Info</Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col md="4" className="mb-3 d-flex align-items-stretch">
              <Card>
                <Card.Img variant="top" src={guessNumber} />
                <Card.Body>
                  <Card.Title>Guess Number</Card.Title>
                  <Card.Subtitle className="text-muted">
                    Front-End App
                  </Card.Subtitle>
                  <Card.Text>
                    Helping young students learn math place values.
                  </Card.Text>
                  <LinkContainer to="/guessnumber">
                    <Button variant="primary">More Info</Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
