import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
export default function Aboutarea() {
  return (
    <div>
      <section className="py-5 my-5">
        <Container className="border py-5" id="about">
          <Row className="gx-0">
            <Col md={{span: 4}}>
              <h3 className="text-primary text-md-end ps-3 ps-md-0 pe-md-3">
                About Me
              </h3>
              {/* <p>
                I'm a maker and I enjoy the process of finding solutions that
                work. Whether it's in the real-world or in code, curiosity
                pushes me on. Challenge beckons me.
              </p>
              <p>I'm a self-taught developer that loves learning. </p> */}
            </Col>
          </Row>
          <Row>
            <Col
              xs={{span: 10, offset: 1}}
              md={{
                span: 6,
                offset: 4,
              }}
              className="border-start  px-3 "
            >
              <p>
                I'm a maker and I enjoy the process of finding solutions that
                work. Whether it's in the real-world or in code, curiosity
                pushes me on. Challenge beckons me.
              </p>
              <p>I'm a self-taught developer that loves learning. </p>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
