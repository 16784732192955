import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {
  Adobecreativecloud,
  Chartdotjs,
  CssThree,
  Express,
  Firebase,
  Git,
  Gnubash,
  Heroku,
  Html5,
  Javascript,
  Jest,
  Linux,
  Mongodb,
  Nodedotjs,
  Postgresql,
  ReactJs,
  Sass,
  Socketdotio,
  Storybook,
  Typescript,
} from '@icons-pack/react-simple-icons';

export default function Skillsarea() {
  const size = 54;
  return (
    <div>
      <section
        className="d-flex flex-column justify-content-center align-items-center py-5"
        name="tools"
        id="tools"
      >
        <Container>
          <Row>
            <Col>
              <h2 className="text-center border-bottom pb-3 mb-3">My Tools</h2>
            </Col>
          </Row>
          {/* <Row>
            <Col xs={3}>
              <div className="icons mx-2 mb-2">
                <ReactJs size={size} />
                <p>React</p>
              </div>
            </Col>
            <Col xs={3}>
              <div className="icons mx-2 mb-2">
                <Nodedotjs size={size} />
                <p>NodeJS</p>
              </div>
            </Col>
            <Col xs={3}>
              <div className="icons mx-2 mb-2">
                <Typescript size={size} />
                <p>Typescript</p>
              </div>
            </Col>
            <Col xs={3}>
              <div className="icons mx-2 mb-2">
                <Jest size={size} />
                <p>Jest</p>
              </div>
            </Col>
            <Col xs={3}>
              <div className="icons mx-2 mb-2">
                <Mongodb size={size} />
                <p>MongoDB </p>
              </div>
            </Col>
          </Row> */}
          <Row className="d-flex justify-content-center mt-5">
            <Col
              md={{span: 6}}
              className="d-flex flex-row flex-wrap justify-content-center align-items-center"
            >
              <div className="icons mx-2 mb-2">
                <ReactJs size={size} />
                <p>React</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Nodedotjs size={size} />
                <p>NodeJS</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Typescript size={size} />
                <p>Typescript</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Jest size={size} />
                <p>Jest</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Mongodb size={size} />
                <p>MongoDB </p>
              </div>
              <div className="icons mx-2 mb-2">
                <Chartdotjs size={size} />
                <p>ChartJS</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Express size={size} />
                <p>ExpressJS</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Firebase size={size} />
                <p>Firebase</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Git size={size} />
                <p>Git</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Heroku size={size} />
                <p>Heroku</p>
              </div>

              <div className="icons mx-2 mb-2">
                <Socketdotio size={size} />
                <p>SocketIO</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Storybook size={size} />
                <p>Storybooks</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Linux size={size} />
                <p>Linux</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Postgresql size={size} />
                <p>PostgreSQL</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Adobecreativecloud size={size} />
                <p>Creative Cloud</p>
              </div>

              <div className="icons mx-2 mb-2">
                <Javascript size={size} />
                <p>Javascript</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Html5 size={size} />
                <p>HTML</p>
              </div>
              <div className="icons mx-2 mb-2">
                <CssThree size={size} />
                <p>CSS</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Sass size={size} />
                <p>Sass</p>
              </div>
              <div className="icons mx-2 mb-2">
                <Gnubash size={size} />
                <p>CLI</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
