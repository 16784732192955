import React from 'react';
import client from '../../sanity-client';
import imageUrl from '@sanity/image-url';

function MainImage({post}) {
  const {mainImage} = post;
  const builder = imageUrl(client);
  function urlFor(source) {
    return builder.image(source);
  }
  return (
    <img
      src={urlFor(mainImage).width(1400).url()}
      className="img-fluid rounded"
      alt={post.mainImageAlt}
    />
  );
}

export default MainImage;
