import React from "react";
import Aboutarea from "./Aboutarea";
import Workarea from "./Workarea";
import Skillsarea from "./Skillsarea";
import Contactarea from "./Contactarea";
import Newheroarea from "./Newheroarea";

export default function Mainpage() {
  return (
    <div className="mainpage">
      <Newheroarea />
      <Aboutarea />
      <Skillsarea />
      <Workarea />
      <Contactarea />
    </div>
  );
}
