import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Mainpage from './components/Mainpage/Mainpage';
import NavComponent from './components/nav/Navcomponent';
import Post from './components/blog/Post';
// import Blog from './components/blog/Pager';
import {output as Output} from './components/blog/react-page-test';
import TimeTracker from './components/workpages/TimeTracker';
import Dashboard from './components/workpages/Dashboard';
import GuessNumber from './components/workpages/GuessNumber';
import Footer from './components/Mainpage/footer';

export default function App() {
  return (
    <div className="app">
      <Router>
        <NavComponent />
        <div>
          <Switch>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/guessnumber">
              <GuessNumber />
            </Route>
            <Route path="/timetracker">
              <TimeTracker />
            </Route>
            <Route exact path="/blog/:id">
              <Post />
            </Route>
            <Route exact path="/blog">
              <Output />
            </Route>
            <Route path="/">
              <Mainpage />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}
