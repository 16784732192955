import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Container, Row, Col, Badge} from 'react-bootstrap';
import client from '../../sanity-client';
import MainImage from './MainImage';
const BlockContent = require('@sanity/block-content-to-react');
export default function Post() {
  const [postObj, setPostObj] = useState(null);
  const {id} = useParams();
  useEffect(() => {
    client
      .fetch(
        `*[_type=='post' && slug.current=='${id}'] { ...,body , title, slug, publishedAt, "mainImage":mainImage.asset->url , 'mainImageAlt':mainImage.asset->alt,abstract, 'categories': categories[]->title}`
      )
      .then((res) => {
        console.log(res[0]);
        setPostObj(res[0]);
      });
  }, [id]);
  const serializers = {
    types: {
      code: (props) => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  };
  const dateformatter = (date) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    date = new Date(date);
    return `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  };
  if (postObj === null) {
    return (
      <div className="blog">
        <Container>
          <h1>Loading...</h1>
        </Container>
      </div>
    );
  }
  if (typeof postObj === 'undefined') {
    return (
      <div className="blog">
        <Container>
          <h1>Sorry... this post was just removed.</h1>
          <a href="/blog">Return to post list.</a>
        </Container>
      </div>
    );
  }
  return (
    <div className="blog">
      <main>
        <article>
          <Container>
            <Row>
              <Col>
                {postObj && postObj.mainImage && <MainImage post={postObj} />}
                <h1>{postObj.title}</h1>
                <p>
                  <small className="text-muted">
                    {dateformatter(postObj.publishedAt)}
                  </small>
                </p>

                {postObj.categories &&
                  postObj.categories.map((category) => {
                    return (
                      <Badge key={category} className="bs-badge-spacing">
                        {category}
                      </Badge>
                    );
                  })}
                <BlockContent blocks={postObj.body} serializers={serializers} />
              </Col>
            </Row>
          </Container>
        </article>
      </main>
    </div>
  );
}
