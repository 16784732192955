import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
export default function Contactarea() {
  return (
    <div>
      <section>
        <Container fluid className="contact" id="contact">
          <Row>
            <Col
              className="bg-primary py-5 d-flex flex-column justify-content-center"
              xs={{span: 4}}
              md={6}
            >
              <h1 className="text-white text-end">Let's Talk</h1>
            </Col>
            <Col className="py-5 d-flex flex-column justify-content-center">
              <h2>My Contact Info</h2>
              <h5>Adam Farver</h5>
              <h6>
                Email:{' '}
                <a
                  href="mailto:adamfarver@gmail.com?subject=I want to work with you!&body=Hey!"
                  target="_blank"
                  rel="noreferrer"
                >
                  adamfarver@gmail.com
                </a>
              </h6>
              <h6>
                Twitter:{' '}
                <a
                  href="http://www.twitter.com/adamfarver"
                  target="_blank"
                  rel="noreferrer"
                >
                  /adamfarver
                </a>
              </h6>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
