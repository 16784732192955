import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import tracker from "../../assets/timetracker.png";
import signin from "../../assets/timetracker-signin.png";
import sprint from "../../assets/timetracker-sprint.png";
import task from "../../assets/timetracker-task.png";
import { Github, Heroku } from "@icons-pack/react-simple-icons";
export default function TimeTracker() {
  return (
    <Container className="work">
      <Row className="mb-3">
        <Col xs={3}>
          <h2 className="mb-0 text-uppercase projecthead">Time Tracker</h2>
          <h6
            className="
			 text-muted"
          >
            Full-Stack Application
          </h6>
        </Col>
        <Col>
          <h6 className="text-muted text-end">Key Features:</h6>
          <ul className="text-end ">
            <li>React Front-End</li>
            <li>Multi-User Interface</li>
            <li>Dynamic Charts</li>
            <li>Custom API</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Carousel
            className="border border-3 border-primary rounded"
            fade
            controls={false}
            indicators={false}
          >
            <Carousel.Item>
              <img className="d-block w-100" src={tracker} alt="Homepage" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={signin} alt="Sign In Page" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={sprint} alt="Sprint Page" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={task} alt="Task Pages" />
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col xs="3" className="text-md-end">
          <h1 className="text-muted text-uppercase">Try</h1>
        </Col>
      </Row>
      <Row className="my-2">
        <Col
          xs={{ offset: 1, span: 9 }}
          md={{ span: 7, offset: 3 }}
          className="border-start border-primary"
        >
          <a
            href="https://adamfarver-timetracker.herokuapp.com"
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary me-2 mb-2"
            rounded
          >
            <Heroku className="me-2" size={20} />
            Live Preview
          </a>
          <a
            href="https://github.com/adamfarver/timetracker"
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary me-2 mb-2"
            rounded
          >
            <Github className="me-2" size={20} />
            Code
          </a>
        </Col>
      </Row>
      <Row>
        <Col xs="3" className="text-md-end">
          <h1 className="text-muted text-uppercase">What</h1>
        </Col>
      </Row>
      <Row className="my-2">
        <Col
          xs={{ offset: 1, span: 9 }}
          md={{ span: 7, offset: 3 }}
          className="border-start border-primary"
        >
          <p>
            This project is a data-driven web application that has advanced
            features, such as dynamic graphs, statistical aggregations,
            authentication/authorization, and a live database for long-term
            storage.
          </p>
        </Col>
      </Row>
      <Row className="my-2">
        <Col xs="3" className="text-md-end">
          <h1 className="text-muted text-uppercase">How</h1>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ offset: 1, span: 9 }}
          md={{ span: 7, offset: 3 }}
          className="border-start border-primary"
        >
          <h5>Technologies</h5>
          <h6 className="callouts">ReactJS</h6>
          <p>General Interface Library</p>
          <h6 className="callouts">ChartJS</h6>
          <p>Charts Library</p>

          <h6 className="callouts">React Hooks</h6>
          <p>State Management / Component Lifecycles</p>

          <h6 className="callouts">React Router</h6>
          <p>Internal App Routing</p>

          <h6 className="callouts">Formik/Yup</h6>
          <p>Form Handling / Validation</p>

          <h6 className="callouts">React-Bootstrap</h6>
          <p>Layout / Styling</p>

          <h6 className="callouts">Recaptcha</h6>
          <p>App Protection</p>

          <h6 className="callouts">NodeJS</h6>
          <p>Base For API software</p>

          <h6 className="callouts">Express</h6>
          <p>API Routing</p>

          <h6 className="callouts">Jest</h6>
          <p>Testing Library</p>

          <h6 className="callouts">Mongoose</h6>
          <p>Database Operations</p>

          <h6 className="callouts">MongoDB Atlas</h6>
          <p>Database</p>

          <h6 className="callouts">Luxon</h6>
          <p>Time Library</p>

          <h6 className="callouts">NodeCron</h6>
          <p>Database Reset</p>
        </Col>
      </Row>
    </Container>
  );
}
